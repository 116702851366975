const configureToggles = (): void => {
  const toggleButtons = document.querySelectorAll(
    '[data-expansion-toggle="true"]',
  );

  const toggleMobileNav = (event: Event): void => {
    event.stopPropagation();

    const element = event.currentTarget as HTMLElement;
    const id = element.getAttribute('data-expansion-id');

    if (id === null || id === '') {
      return;
    }

    const expansion = document.querySelector(
      `[data-expansion-panel="true"][data-expansion-id=${id}]`,
    );
    if (expansion) {
      const currentSetting = expansion.getAttribute('data-visible');

      const visibility = currentSetting === 'true' ? 'false' : 'true';

      expansion.setAttribute('data-visible', visibility);

      const handleClickOutside = (e: Event): void => {
        const target = e.target as HTMLElement;
        if (!expansion.contains(target)) {
          closeExpansion();
          document.removeEventListener('click', handleClickOutside);
        }
      };

      const openExpansion = (): void => {
        expansion.setAttribute('data-visible', 'true');
        document.addEventListener('click', handleClickOutside);
      };

      const closeExpansion = (): void => {
        expansion.setAttribute('data-visible', 'false');
        document.removeEventListener('click', handleClickOutside);
      };

      if (visibility === 'true') {
        openExpansion();
      } else {
        closeExpansion();
      }
    }
  };

  for (const toggle of toggleButtons) {
    toggle.addEventListener('click', toggleMobileNav);
  }
};

document.addEventListener('astro:page-load', configureToggles);
